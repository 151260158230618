import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import Skeleton from "react-loading-skeleton";
import { loadStripe } from "@stripe/stripe-js";
import EarlyCheckoutForm from "./EarlyCheckoutForm";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { paymentActions } from "../store/payment.slice";
import HttpStatus from "../helpers/status.enum";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Modal, Button, Form } from "react-bootstrap";
import SpinnerLoader from "./SpinnerLoader";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const UpgradeItems = ({
  itemsList,
  needToEmailName,
  cmsContent,
  hideUpgradeYourStay, // If no upsells and only partner in upgrade your stay then we need this function
  emailStatusChange = () => {},
  getAgreementList = () => {},
}) => {
  const bookingSlug = useSelector((data) => {
    return data.checkIn.value;
  });
  const encryKey = process.env.REACT_APP_STRIPE_ENCRYPT_KEY;
  const intentType = 1; // Damage Waiver Payment Intent Type
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const dispatch = useDispatch();

  const [itemSlug, setItemSlug] = useState("");
  const [selectedItemDetail, setSelectedItemDetail] = useState({
    item_name: "",
    item_desc: "",
    item_price: "",
    item_status_id: "",
  });
  const [isIntentCreate, setIsIntentCreate] = useState(false);
  const [quantitiesDropDown, setQuantitiesDropDown] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [itemPrice, setItemPrice] = useState(0);
  const [calculatedItemPrice, setCalculatedItemPrice] = useState(0);
  const [stripePromiseForItemPurchase, setStripePromiseForItemPurchase] =
    useState(null);
  const [isDesabled, setIsDesabled] = useState(false);
  const [showpurchaseItemModel, setShowitemPurchaseModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [checkoutName, setCheckoutName] = useState("");
  const [checkoutEmail, setCheckoutEmail] = useState("");
  const [clientSecret, setClientSecret] = useState(false);
  const [paymentSlug, setPaymentSlug] = useState("");
  const [swiperRef, setSwiperRef] = useState(null);
  // const [isEmailNameVerified, setEmailNameVerified] = useState(needToEmailName);
  const isEmailNameVerified = needToEmailName;
  const [selectQuantityShow, setSelectQuantityShow] = useState(false);
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [stripeId, setStripeId] = useState(null);
  const [upsellPurchase, setUpsellPurchase] = useState(false);
  const [isWaiverPurchased, setIsWaiverPurchased] = useState(false);
  const [securityDepositAlert, setSecurityDepositAlert] = useState({
    non_refundable_description: "",
    description: "",
  });

  const options = {
    clientSecret: clientSecret,
    appearance: {
      /*...*/
    },
  };

  useEffect(() => { // If quantitiesDropDown length is 1 then directly go for payment
    if(selectQuantityShow && quantitiesDropDown.length === 1){
      createPaymentIntent()
    }
  }, [selectQuantityShow, quantitiesDropDown])

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCheckoutNameChange = (event) => {
    const inputValue = event.target.value;
    setCheckoutName(inputValue);
    const isEmailValid = validateEmail(checkoutEmail);
    if (inputValue.length > 2 && isEmailValid) {
      setIsDesabled(false);
    } else {
      setIsDesabled(true);
    }
  };

  const handleCheckoutEmailChange = (event) => {
    const inputValue = event.target.value;
    setCheckoutEmail(inputValue);
    const isEmailValid = validateEmail(inputValue);
    if (checkoutName.length > 2 && isEmailValid) {
      setIsDesabled(false);
    } else {
      setIsDesabled(true);
    }
  };

  const openPurchaseItemModel = (slug, is_waiver) => {
    if (isEmailNameVerified) {
      setIsDesabled(true);
    }
    if (is_waiver) {
      setUpsellPurchase(false);
      const selectedItem = itemsList.find((item) => item.is_waiver);
      if (!selectedItem) return;
      setSecurityDepositAlert((prevState) => ({
        ...prevState,
        non_refundable_description: selectedItem.non_refundable_description,
        description: selectedItem.description,
      }));
      if (isEmailNameVerified) {
        // If no then open the email popup
        setShowitemPurchaseModal(true);
      } else {
        // If yes then ready to go for payment
        createPaymentIntent(false);
      }
    } else {
      setUpsellPurchase(true);
      const selectedItem = itemsList.find((item) => item.slug === slug);
      if (!selectedItem) return;
      if (!isEmailNameVerified) {
        setSelectQuantityShow(true);
      }
      setSelectedItemDetail({
        item_name: selectedItem.title,
        item_desc: selectedItem.description,
        item_status_id: selectedItem.status_id,
      });
      setItemPrice(selectedItem.total_price);
      const quantities = Array.from(
        { length: selectedItem.quantity },
        (_, i) => i + 1
      );
      setQuantitiesDropDown(quantities);
      if(quantities.length > 1 || isEmailNameVerified){ // If maxQuantity = 1 then prevent to open up the model till the payment begin to improve UX Except if name and email verified
        setShowitemPurchaseModal(true);
      }
      setItemSlug(slug);
    }
  };

  // close the payment model
  const closePaymentModel = () => {
    setShowitemPurchaseModal(false);
    setIsIntentCreate(false);
    setSelectedQuantity(1);
    setStripePromiseForItemPurchase(null);
    setCheckoutName("");
    setCheckoutEmail("");
    setSelectQuantityShow(false);
    setShowCreditCardModal(false);
    setLoader(false);
    setQuantitiesDropDown([]); // Reset the quantities dropdown    
  };

  const handleItemQuantityChange = (e) => {
    const calculate = (itemPrice * e.value).toFixed(2);
    setCalculatedItemPrice(calculate);
    setSelectedQuantity(e?.value);
  };

  const createPaymentIntent = async (upsellPayment = true) => {
    setLoader(true);
    const formData = {
      booking_slug: bookingSlug,
      checkout_name: checkoutName,
      checkout_email: checkoutEmail,
      intent_type: upsellPayment ? 4 : 1,
      needToEmailName: isEmailNameVerified,
      ...(upsellPayment ? {
        item_slug: itemSlug,
        quantity: parseInt(selectedQuantity, 10),
      } : {
        is_upsell_wavier: 1,
      }),
    };
    try {
      const result = await dispatch(paymentActions.createPaymentIntent(formData));
      const responses = result?.payload;
      if (responses?.status === HttpStatus.HTTP_OK) {
        const paymentRes = responses.result;
        setClientSecret(paymentRes?.client_secret);
        setCustomerId(paymentRes?.customer_id);
        setStripeId(paymentRes?.id);
        setPaymentSlug(paymentRes?.payment_slug);
        // setEmailNameVerified(false);
        if(needToEmailName){ 
          emailStatusChange()
        }
        if (upsellPayment) {
          setIsIntentCreate(true);
          if (paymentRes?.host_account_id) {
            // On e-commerece payment need to pass stripe publishkey based on connected id
            const encryptedData = paymentRes?.host_account_id; // Replace with your base64-encoded encrypted data
  
            const decryptedData = decryptData(encryptedData, encryKey);
            const stripePromise = await loadStripe(
              process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
              { stripeAccount: decryptedData }
            );
            setStripePromiseForItemPurchase(stripePromise);
            setShowitemPurchaseModal(true);
          }
        } else {
          setShowCreditCardModal(true);
        }
      } else {
        toast(responses?.message);
        if(responses?.status === HttpStatus.HTTP_UNPROCESSABLE_ENTITY && !upsellPayment){
          setIsWaiverPurchased(true);
        }
        closePaymentModel(); // close the modal if intent failed to create
      }
    } catch (error) {
      console.error("Error creating payment intent:", error);
    } finally {
      setLoader(false);
    }
  };

  const decryptData = (encryptedData, key) => {
    // Decrypt the data
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    // Convert decrypted bytes to a UTF-8 string
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedData;
  };

  // After the email and name verified then ready to go for payment or asking for quantity
  const nextStep = () => {
    if (upsellPurchase) {
      // If not waiver then asking for quantity
      if(quantitiesDropDown.length === 1){
        createPaymentIntent();
      } else {
        setSelectQuantityShow(true);
      }
    } else {
      // If waiver then directly go for payment
      createPaymentIntent(false);
      setShowitemPurchaseModal(false);
    }
  };

  const handleClosePaymentModal = () => {
    setShowCreditCardModal(false);
    setLoader(false);
  };

  const afterPurchaseWaiver = () => {
    getAgreementList(true);
    setIsWaiverPurchased(true);
    closePaymentModel();
    if(itemsList?.length === 1){
      hideUpgradeYourStay(); // If no upsells and only partner in upgrade your stay then we need this function
    }
  };

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: cmsContent?.custom_upsell_description,
        }}
      />
      <div className="swiperSliderone pink-slider">
        <Swiper
          onSwiper={setSwiperRef}
          spaceBetween={5}
          slidesPerView={1.9} // Set slidesPerView to 1.5
          navigation={false}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {itemsList?.map((item, index) => {
            if (isWaiverPurchased && item?.is_waiver) {
              return null;
            }
            return (
              <SwiperSlide key={index}>
                <div className="localMap">
                  <figure>
                    {item?.image ? (
                      <img src={item?.image} alt="" />
                    ) : (
                      <Skeleton duration={1} height={300} />
                    )}
                  </figure>
                  <div className="content">
                    <span className="dataLabel d-block w-100 text-center">
                      {item?.title}
                    </span>
                    <div className="w-100 mt-3">
                      <Button
                        className="fillbutton"
                        onClick={() =>
                          openPurchaseItemModel(
                            item?.slug,
                            item?.is_waiver || false
                          )
                        }
                      >
                        {
                          item?.is_waiver ? (loader && !upsellPurchase ? <SpinnerLoader /> : item?.cta_title) : (itemSlug === item?.slug ? (loader && upsellPurchase && quantitiesDropDown.length === 1 ? <SpinnerLoader /> : item?.cta_title)
                            : item?.cta_title)
                        }
                      </Button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <Modal
        show={showpurchaseItemModel}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="earlycheckin eccomerceItem"
      >
        <Modal.Header
          closeButton
          onClick={() => closePaymentModel()}
        ></Modal.Header>
        <Modal.Body>
          {upsellPurchase && (
            <>
              <div className="item_pur_title">
                <h2
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html: selectedItemDetail.item_name,
                  }}
                ></h2>
                <h2
                  className="mb-2 upsell-modal-title"
                  dangerouslySetInnerHTML={{
                    __html: `USD $<span className="">${
                      selectedQuantity > 1 ? calculatedItemPrice : itemPrice
                    }</span>`,
                  }}
                ></h2>
              </div>
              <h6
                className="early_checkin_popup_heading"
                dangerouslySetInnerHTML={{
                  __html: selectedItemDetail.item_desc,
                }}
              ></h6>
              <h6
                className="early_checkin_popup_heading"
                dangerouslySetInnerHTML={{
                  __html:
                    selectedItemDetail.item_status_id == 1
                      ? cmsContent?.manual_approved_checkout_alert
                      : cmsContent?.auto_approved_checkout_alert,
                }}
              ></h6>
            </>
          )}
          {/* Asking for email and name */}
          {isEmailNameVerified && !selectQuantityShow && (
            <>
              <React.Fragment>
                <span className="dataLabel3" htmlFor="name">
                  Name
                </span>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="name"
                    value={checkoutName}
                    onChange={handleCheckoutNameChange}
                    className="input-text2-modal"
                    required
                  />
                </Form.Group>

                <span className="dataLabel3" htmlFor="email">
                  Email
                </span>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    id="email"
                    value={checkoutEmail}
                    onChange={handleCheckoutEmailChange}
                    className="input-text2-modal"
                  />
                </Form.Group>
              </React.Fragment>
              <div className="d-flex justify-content-between mb-4">
                <Button
                  className="outlinebutton cancel_btn"
                  onClick={() => closePaymentModel()}
                >
                  Cancel
                </Button>
                <Button
                  className="fillbutton"
                  onClick={nextStep}
                  disabled={isDesabled ? true : false}
                >
                  {loader ? <SpinnerLoader /> : " Checkout"}
                </Button>
              </div>
            </>
          )}
          {/* For select the quantity */}
          {selectQuantityShow && quantitiesDropDown?.length > 1 && (
            <>
              <hr className="mb-2" />
              {!isIntentCreate ? (
                <>
                  <div className="range custom_select_drop_box mb-3">
                    <React.Fragment>
                      <span className="dataLabel3" htmlFor="hours">
                        Select Quantity:
                      </span>
                      <Dropdown
                        options={quantitiesDropDown}
                        onChange={handleItemQuantityChange}
                        value={selectedQuantity.toString()}
                      />
                    </React.Fragment>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <Button
                      className="outlinebutton cancel_btn"
                      onClick={() => closePaymentModel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="fillbutton"
                      onClick={createPaymentIntent}
                    >
                      {loader ? <SpinnerLoader /> : "Proceed"}
                    </Button>
                  </div>
                </>
              ) : (
                <React.Fragment>
                  <span className="dataLabel3" htmlFor="hours">
                    Quantity:
                  </span>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      value={selectedQuantity}
                      disabled={true}
                      className="input-text2-modal"
                    />
                  </Form.Group>
                </React.Fragment>
              )}
            </>
          )}
          {isIntentCreate && stripePromiseForItemPurchase && (
            <Elements stripe={stripePromiseForItemPurchase} options={options}>
              <EarlyCheckoutForm
                closeEarlyCheckinPaymentModel={closePaymentModel}
                bookingSlug={bookingSlug}
                paymentSlug={paymentSlug}
                itemSlug={itemSlug}
                itemPayButtonStatus={selectedItemDetail?.item_status_id} // Passing Item Status for show Custom Upsell Button Text
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>

      {/* Add Credit Card & Pay */}
      <Modal
        show={showCreditCardModal}
        onHide={() => handleClosePaymentModal()}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="addcreditcard"
        backdrop="static"
      >
        <Modal.Header
          closeButton
          onClick={() => handleClosePaymentModal()}
        ></Modal.Header>
        <Modal.Body>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              closeCheckoutModal={closePaymentModel}
              bookingSlug={bookingSlug}
              clientSecret={clientSecret}
              customerId={customerId}
              stripeId={stripeId}
              paymentSlug={paymentSlug}
              intentType={intentType}
              cmsContent={cmsContent}
              securityDepositAlert={securityDepositAlert}
              nextUpsellCardStep={afterPurchaseWaiver}
            />
          </Elements>
        </Modal.Body>
      </Modal>
      {/* End i have read the agreement */}
    </>
  );
};

export default UpgradeItems;
